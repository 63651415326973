// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/tmp/34d9944e/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/tmp/34d9944e/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-schedule-30-s-js": () => import("/tmp/34d9944e/src/pages/schedule/30s.js" /* webpackChunkName: "component---src-pages-schedule-30-s-js" */),
  "component---src-pages-schedule-40-s-js": () => import("/tmp/34d9944e/src/pages/schedule/40s.js" /* webpackChunkName: "component---src-pages-schedule-40-s-js" */),
  "component---src-pages-schedule-d-1-js": () => import("/tmp/34d9944e/src/pages/schedule/d1.js" /* webpackChunkName: "component---src-pages-schedule-d-1-js" */),
  "component---src-pages-about-mdx": () => import("/tmp/34d9944e/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-teams-mdx": () => import("/tmp/34d9944e/src/pages/teams.mdx" /* webpackChunkName: "component---src-pages-teams-mdx" */)
}

